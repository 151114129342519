// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-input {
  position: relative;
}
.auth-input img:first-child {
  position: absolute;
  top: 30%;
  left: 5%;
  z-index: 20;
  height: 20px;
}
.auth-input img:last-child {
  position: absolute;
  top: 30%;
  right: 5%;
  z-index: 20;
  height: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AAEJ;AAAE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAEJ","sourcesContent":[".auth-input {\n  position: relative;\n  img:first-child {\n    position: absolute;\n    top: 30%;\n    left: 5%;\n    z-index: 20;\n    height: 20px;\n  }\n  img:last-child {\n    position: absolute;\n    top: 30%;\n    right: 5%;\n    z-index: 20;\n    height: 20px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
