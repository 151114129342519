// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-links {
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgb(247 148 29 / var(--tw-text-opacity));
  transition-duration: 300ms;
  font-weight: 300;
}

.footer-links:hover {
  --tw-text-opacity: 1;
  color: rgb(10 33 237 / var(--tw-text-opacity));
}

.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  transition-duration: 300ms;
}

.footer-icon-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  transition-duration: 300ms;
}

input {
  padding: 16px 0px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #ffffff;
}

.main-bg-color {
  background: #294093;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.scss"],"names":[],"mappings":"AACE;EAAA,oBAAA;EACA,oBAAA;EAAA,+CAAA;EACA,0BAAA;EACA;AAHA;;AAOA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA;AALA;;AASA;EAAA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EACA;AALA;;AAQF;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".footer-links {\n  @apply leading-9;\n  @apply text-secondary-500;\n  @apply duration-300;\n  @apply font-light;\n}\n\n.footer-links:hover {\n  @apply text-primary-500;\n}\n\n.footer-icon {\n  @apply flex;\n  @apply justify-center;\n  @apply items-center;\n  @apply w-7;\n  @apply h-7;\n  @apply duration-300;\n}\n\n.footer-icon-mobile {\n  @apply flex;\n  @apply justify-center;\n  @apply items-center;\n  @apply w-10;\n  @apply h-10;\n  @apply duration-300;\n}\n\ninput {\n  padding: 16px 0px;\n  color: #ffffff;\n  font-weight: 500;\n  font-size: 12px;\n}\n\ninput:focus {\n  outline: none;\n}\n\ninput::placeholder {\n  color: #ffffff;\n}\n\n.main-bg-color{\n  background: #294093;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
