// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Euclid Circular A Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Euclid Circular A Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Euclid Circular A Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Euclid Circular A Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Euclid Circular A SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Euclid Circular A Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Euclid Circular A Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Euclid Circular A Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Euclid Circular A Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
@font-face {
  font-family: "Euclid Circular A SemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/font/stylesheet.css"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,+DAAA;AACF;AAEA;EACE,uCAAA;EACA,+DAAA;AAAF;AAGA;EACE,wCAAA;EACA,+DAAA;AADF;AAIA;EACE,sCAAA;EACA,+DAAA;AAFF;AAKA;EACE,yCAAA;EACA,+DAAA;AAHF","sourcesContent":["@font-face {\n  font-family: \"Euclid Circular A Bold\";\n  src: url(\"Euclid Circular A Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Euclid Circular A Medium\";\n  src: url(\"Euclid Circular A Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Euclid Circular A Regular\";\n  src: url(\"Euclid Circular A Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Euclid Circular A Light\";\n  src: url(\"Euclid Circular A Light.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Euclid Circular A SemiBold\";\n  src: url(\"Euclid Circular A SemiBold.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
