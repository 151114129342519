// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav_style {
  color: #000000;
  font-weight: 300;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  margin: 0px 1rem;
  display: inline-block;
  position: relative;
}

.nav_style_mobile {
  color: #ffffff;
  font-weight: 300;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  margin: 0.5rem 0rem;
  display: inline-block;
  position: relative;
}

.nav_style.active {
  color: #294093;
  font-weight: 400;
  font-family: Euclid Circular A Medium, sans-serif;
}

.nav_style:hover {
  color: #294093;
  font-weight: 400;
  font-family: Euclid Circular A Medium, sans-serif;
}

.nav-bar-floated {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,gKAAA;EAAA,wJAAA;EAAA,iLAAA;EAAA,0BAAA;EACA,wDAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,gKAAA;EAAA,wJAAA;EAAA,iLAAA;EAAA,0BAAA;EACA,wDAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,iDAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,iDAAA;AACF;;AAEA;EACE,oCAAA;EACA,kCAAA;UAAA,0BAAA;AACF","sourcesContent":[".nav_style {\n  color: #000000;\n  font-weight: 300;\n  @apply transition;\n  @apply ease-in-out;\n  margin: 0px 1rem;\n  display: inline-block;\n  position: relative;\n}\n\n.nav_style_mobile {\n  color: #ffffff;\n  font-weight: 300;\n  @apply transition;\n  @apply ease-in-out;\n  margin: 0.5rem 0rem;\n  display: inline-block;\n  position: relative;\n}\n\n.nav_style.active {\n  color:  #294093;\n  @apply font-normal;\n  @apply font-euclidMedium\n}\n\n.nav_style:hover {\n  color:  #294093;\n  @apply font-normal;\n  @apply font-euclidMedium\n}\n\n.nav-bar-floated {\n  background: rgba(255, 255, 255, 0.2);\n  backdrop-filter: blur(6px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
